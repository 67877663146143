import React, {useEffect, useMemo, useRef} from 'react';
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {formatScientific} from '../../utils/utils'

import './tables.css'


const query = `
query VariantSplitPopulationQuery($varId: String!) {
  variants (varId: $varId, splitByPopulation: true) {
    alleleCount
    alleleNum
    alleleFreq
    nHomozygotes
    population
  }
}
`


const ancestryDisplayMap = {
    XBI: "British/Irish",
    XAF: "African",
    XSA: "South-Asian"
}

const PopulationTable = (props) => {
    const {data} = props;
    const gridRef = useRef();

    if (data) {
        data.map((d) => {
            const ancestry = d.population.toUpperCase();
            if (ancestry in ancestryDisplayMap) {
                d.displayAncestry = ancestryDisplayMap[ancestry];
            } else {
                d.displayAncestry = ancestry;
            }
        });

        data.sort((a, b) => a.displayAncestry < b.displayAncestry ? -1 : 1)
    }

    const columnDefs = [
        {
            field: "displayAncestry", headerName: "Ancestry",
            minWidth: 120
        },
        {
            field: "alleleCount",
            minWidth: 90
        },
        {
            field: "alleleNum",
        },
        {
            field: "nHomozygotes",
            minWidth: 110
        },
        {
            field: "alleleFreq", headerName: "Allele Frequency",
            valueFormatter: (params) => formatScientific(params.value)
        }
    ]

    useEffect(() => {
        const gridApi = gridRef.current?.api;

        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }

    }, [data]);

    const defaultColDefs = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            resizable: true,
            sortable: true,
        }
    }, [])



    return (
        <div className="ag-theme-alpine" style={{
            height: "75vh",
            textAlign: "left"
        }
        }>

            <AgGridReact
                domLayout='autoHeight'
                ref={gridRef}
                rowData={data}
                columnDefs={columnDefs}
                defaultColDef={defaultColDefs}
                rowBuffer={35}
            >
            </AgGridReact>
        </div>
    );
};


export default PopulationTable;
