import React, {useEffect, useMemo} from 'react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import '../VariantAggregateTable/VarInsGene.css'

import Loading from "../Loading/Loading";
import columnDefs from "../VariantAggregateTable/variantsRegionColumnDefs";
import VariantAggregateTable from "../VariantAggregateTable/VariantAggregateTable";
import {useFetchGraphQL} from "../../hooks/useFetchGraphQL";
import Typography from "@mui/material/Typography";

const geneQuery = `
query SuperAwesomeQuery($name: String!) {
  gene (name: $name) {
    name
    variants {
        varId
        rsID
        Chrom
        Pos
        Ref
        Alt
        nHomozygotes
        HGVSp
        maxImpact
        maxConsequence
        alleleCount
        alleleNum
        alleleFreq
        nHomozygotes
    }
  }
}
`


const GenePage = (props) => {
    const {geneId} = props;

    const {data, errors, loading} = useFetchGraphQL(geneQuery, {"name": geneId.toUpperCase()})

    useEffect(() => {
        document.title = "deCAF - " + geneId;
    }, [geneId]);

    const customColDefs = useMemo(() => {
        const includeFields = [
            'varId',
            'rsID',
            // 'geneSymbol',
            'maxImpact',
            'maxConsequence',
            'HGVSp',
            'alleleCount',
            'alleleNum',
            'alleleFreq',
            'nHomozygotes'
        ]

        return columnDefs.filter(d => includeFields.includes(d.field))
    }, []);


    if (loading) {
        return (
            <Loading/>
        )
    } else if (errors) {
        return <>
            <Typography>Error fetching data</Typography>
            <Typography>Could not fetch data for gene: {geneId}</Typography>
        </>
    } else {
        // @ts-ignore
        return (
            <>
                <VariantAggregateTable
                    customColDefs={customColDefs}
                    rowData={data.gene.variants}
                    pageType="Gene"
                    pageTypeValue={geneId}
                />
            </>
        );
    }
};

export default GenePage;
