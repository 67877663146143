import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';


const About = () => {
    return (
        <Grid container spacing={2} style={{ textAlign: "left" }}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
                <h2 style={{ textAlign: "center" }}>We thank the participants of the UKB</h2>

                <p>The sequencing of 450,000 WGS individuals from the UKB (project nr. 52293), including the 150,119 described here has been funded by the UKB WGS consortium consisting of UK Government's research and innovation agency, UK Research and Innovation (UKRI), through the Industrial Strategy Challenge Fund, The Wellcome Trust and the pharmaceutical companies Amgen, AstraZeneca, GlaxoSmithKline and Johnson &amp; Johnson.</p>

                <p>DNA sequencing was performed at the Wellcome Trust Sanger Institute and deCODE genetics.</p>

                <p>The number of carriers presented is the sum of the number of carriers in three cohorts defined in the UK biobank; British Irish, African and South-Asian.  Individuals not included in one of these three cohorts may be additional carriers.</p>

                <p>The position are expressed according to hg38 (Genome Reference Consortium Human Build 38).</p>
                
                <p>The variant ID is constructed with the following structure: Chromosome-Hg38position-Reference-Alternative.</p>
                
                <p>Further description is given in:</p>

                <h3>The sequences of 150,119 genomes in the UK biobank</h3>

                <p><i>Bjarni V. Halldorsson, Hannes P. Eggertsson, Kristjan H.S. Moore, Hannes Hauswedell, Ogmundur Eiriksson, Magnus O. Ulfarsson, Gunnar Palsson, Marteinn T. Hardarson, Asmundur Oddsson, Brynjar O. Jensson,  Snaedis Kristmundsdottir, Brynja D. Sigurpalsdottir, Olafur A. Stefansson, Doruk Beyter, Guillaume Holley, Vinicius Tragante, Arnaldur Gylfason, Pall I. Olason, Florian Zink, Margret Asgeirsdottir, Sverrir T. Sverrisson, Brynjar Sigurdsson, Sigurjon A. Gudjonsson, Gunnar T. Sigurdsson, Gisli H. Halldorsson, Gardar Sveinbjornsson, Kristjan Norland, Unnur Styrkarsdottir, Droplaug N. Magnusdottir, Steinunn Snorradottir, Kari Kristinsson, Emilia Sobech, Helgi Jonsson, Arni J. Geirsson, Isleifur Olafsson, Palmi Jonsson, Ole Birger Pedersen, Christian Erikstrup, Søren Brunak, Sisse Rye Ostrowski, DBDS Genetic Consortium, Gudmar Thorleifsson, Frosti Jonsson, Pall Melsted, Ingileif Jonsdottir, Thorunn Rafnar, Hilma Holm, Hreinn Stefansson, Jona Saemundsdottir, Daniel F. Gudbjartsson, Olafur T. Magnusson, Gisli Masson, Unnur Thorsteinsdottir,  Agnar Helgason,  Hakon Jonsson,  Patrick Sulem, Kari Stefansson</i></p>

                <p>Nature, July 28th 2022</p>

                <a href="https://www.nature.com/articles/s41586-022-04965-x">https://www.nature.com/articles/s41586-022-04965-x</a>
                <br/><br/>
            </Grid>
        </Grid>
    );
};
export default About;