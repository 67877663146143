import React, {useEffect, useMemo} from 'react';
import 'ag-grid-enterprise';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import '../VariantAggregateTable/VarInsGene.css'

import Loading from "../Loading/Loading";
import columnDefs from "../VariantAggregateTable/variantsRegionColumnDefs";
import VariantAggregateTable from "../VariantAggregateTable/VariantAggregateTable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useFetchGraphQL} from "../../hooks/useFetchGraphQL";

const regionQuery = `
query SuperAwesomeQuery($regionStr: String!) {
  region (regionStr: $regionStr) {
    regionStr
    variants {
        varId
        rsID
        Chrom
        Pos
        Ref
        Alt
        nHomozygotes
        HGVSp
        maxImpact
        maxConsequence
        alleleCount
        alleleNum
        alleleFreq
        nHomozygotes
        geneSymbol
    }
  }
}
`

const RegionPage = (props) => {
    const maxRegionSize = 100000;

    const {region} = props;
    const {data, errors, loading} = useFetchGraphQL(regionQuery, {"regionStr": region})

    useEffect(() => {
        document.title = "deCAF - " + region;
    }, [region])

    const customColDefs = useMemo(() => {
        return columnDefs;
    }, []);

    const validateRegion = (region) => {
        const [chrom, positions] = region.split(":");
        let [begin, end] = positions.split("-").map((val) => parseInt(val));
        const reRegion = /chr([1-9][0-9]?|[xX]|[yY]):[0-9]/

        let isValid = region.toLowerCase().trim().search(reRegion) === 0;
        isValid = isValid && (end >= begin);
        isValid = isValid && (end - begin <= maxRegionSize);

        return isValid;
    }

    const isRegionValid = validateRegion(region);

    if (!isRegionValid) {
        return (<Box>
            <Typography>
                The region '{region}' appears to be invalid.
            </Typography>
            <br/><br/>
            <Typography>
                Example valid region: chr1:55039479-55039500
            </Typography>
            <Typography>
                Max region Size: {maxRegionSize}
            </Typography>
        </Box>)
    } else if (loading) {
        return (
            <Loading/>
        )
    } else if (errors) {
        return <>
            <Typography>Error fetching data</Typography>
            <Typography>Could not fetch data for region: {region}</Typography>
        </>
    } else {
        return (
            <>
                <VariantAggregateTable
                    customColDefs={customColDefs}
                    rowData={data.region.variants}
                    pageType="Region"
                    pageTypeValue={region}
                />
            </>
        );
    }
};


export default RegionPage;
