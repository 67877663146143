import React from 'react';
import './App.css';
import Menu from "./components/Menu/Menu";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {withPassRouteMatches} from "./utils/utils"
import GenePage from "./components/Pages/GenePage";

import Frontpage from "./components/Pages/Frontpage";
import Feedback from "./components/Pages/Feedback";
import About from "./components/Pages/About";
import Terms from "./components/Pages/Terms";
import VariantPage from "./components/Pages/VariantPage";
import RegionPage from "./components/Pages/RegionPage";

import { LicenseManager } from  'ag-grid-enterprise'

LicenseManager.setLicenseKey("CompanyName=Decode genetics,LicensedGroup=Decode genetics,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-029654,SupportServicesEnd=23_June_2023_[v2]_MTY4NzQ3NDgwMDAwMA==d963c23bfc3ee8578b30c5cb3769dc64")


function App() {
    const RoutedFrontpage = withPassRouteMatches(Frontpage);
    const RoutedGenePage = withPassRouteMatches(GenePage);
    const RoutedVariant = withPassRouteMatches(VariantPage);
    const RoutedRegion = withPassRouteMatches(RegionPage);
    const RoutedFeedback = withPassRouteMatches(Feedback);
    const RoutedAbout = withPassRouteMatches(About);
    const RoutedTerms = withPassRouteMatches(Terms);

    return (
        <BrowserRouter>
            <div className="App">
                <Menu/>
                <br/>
                <Routes>
                    <Route path="/" element={<RoutedFrontpage/>}/>
                    <Route path="/gene/:geneId" element={<RoutedGenePage/>}/>
                    <Route path="/variant/:varId" element={<RoutedVariant/>}/>
                    <Route path="/region/:region" element={<RoutedRegion/>}/>
                    <Route path="/feedback" element={<RoutedFeedback/>}/>
                    <Route path="/about" element={<RoutedAbout/>}/>
                    <Route path="/terms" element={<RoutedTerms/>}/>
                </Routes>
            </div>
        </BrowserRouter>

    );
}

export default App;
