import * as React from 'react';
import {SyntheticEvent, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {styled} from '@mui/material/styles';
import {clamp} from '../../utils/utils'

// @ts-ignore
import logo from "./decaf.PNG";
import {Popper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useFetchGraphQL} from "../../hooks/useFetchGraphQL";


type Option = {
    type: string,
    optionLabel: string
}

const StyledPopper = styled(Popper)(({theme}) => ({
    '& .MuiAutocomplete-groupLabel': {
        textAlign: "left"
    },
}));

const isRegionOrVarint = (value: string): boolean[] => {
    const reRegion = /chr([1-9][0-9]?|[xX]|[yY]):[0-9]/
    // example of real variant chr1:55040966:SG:2 and chr1:55040966:SG
    const reVariant = new RegExp(reRegion.source + "+:");
    const isRegionString = value.toLowerCase().trim().search(reRegion) === 0;
    const isVariant = value.toLowerCase().trim().search(reVariant) === 0;

    return [isRegionString, isVariant];
}

const constructRegionOptions = (regionStr: string): string[] => {
    const [chrom, positions] = regionStr.split(":");
    let [begin, end] = positions.split("-").map((val) => parseInt(val));

    const nZeros = Math.floor(Math.log10(begin));

    const maxPadding = 50000;
    const maxPaddingZeros = Math.floor(Math.log10(maxPadding)) + 1;
    const largestRegionZeros = clamp(nZeros, 1, maxPaddingZeros);

    let regions = []

    // if user is specifying a valid region
    // we want to include the region at the top of the list
    if (end && end >= begin) {
        regions.push(
            `${chrom}:${begin}-${end}`,
        )
    }

    for (let i = largestRegionZeros - 1; i >= 0; i--) {
        const padding = clamp(5 * Math.pow(10, i), 0, maxPadding);
        const b = Math.max(0, begin - padding); // cannot start below 0
        const e = begin + padding
        regions.push(
            `${chrom}:${b}-${e}`,
        )
    }
    return regions;
}


// @ts-ignore
const DecafAutocomplete = (props) => {
    const {renderInput} = props;

    let navigate = useNavigate();

    const [inputValue, setInputValue] = React.useState("");
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const [matchedValues, setMatchedValues] = React.useState<Option[]>([]);


    const navigateStuff = async (e: SyntheticEvent, option: Option) => {
        // e.g. navigate to /region/chr1:1-10
        navigate(`${option.type.toLowerCase()}/${option.optionLabel}`)
    };


    const searchGeneQuery = `query genesSearch($name: String!) {
                     genes(searchStr: $name)
                 }`;
    const searchVariantQuery = `query variantSearch($searchStr: String!) {
                         variantNames(searchStr: $searchStr)
                     }`;

    const {
        fetchData: searchGeneData
    } = useFetchGraphQL(searchGeneQuery, {"name": inputValue}, false);

    const {
        fetchData: fetchVariantData
    } = useFetchGraphQL(searchVariantQuery, {"searchStr": inputValue}, false);


    useEffect(() => {
        const asyncWrap = async () => {
            const [isRegionString, isVariant] = isRegionOrVarint(inputValue);
            let newMatchedValues: Option[] = [];

            const timeout = setTimeout(() => {
                setLoading(true);
                setMatchedValues([]);
            }, 100);

            if (inputValue.length > 0) {
                if (isVariant) {
                    const {data} = await fetchVariantData();

                    newMatchedValues = newMatchedValues.concat(
                        data.variantNames.map((s: string) => {
                            return {type: "Variant", optionLabel: s};
                        })
                    );
                }
                if (isRegionString) {
                    const regions = constructRegionOptions(inputValue);

                    newMatchedValues = newMatchedValues.concat(
                        regions.map((s: string) => {
                            return {type: "Region", optionLabel: s};
                        })
                    );

                } else if (inputValue.length > 0) {
                    const {data} = await searchGeneData();

                    newMatchedValues = newMatchedValues.concat(
                        data.genes.slice(0, 10).map((s: string) => {
                            return {type: "Gene", optionLabel: s}
                        })
                    )
                }
            }

            clearTimeout(timeout);
            setLoading(false);
            setMatchedValues(newMatchedValues);
        }

        asyncWrap().catch((e) => {
            console.error(e);
        })
    }, [inputValue])


    const handleInputChange = async (e: SyntheticEvent, newVal: string) => {
        if (!newVal) {
            newVal = "";
        }
        newVal = newVal.trim();

        const [isRegionString, isVariant] = isRegionOrVarint(newVal);

        if (isRegionString || isVariant) {
            const [chrom, positions] = newVal.split(":");
            let [begin, end] = positions.split("-").map((val: string) => parseInt(val));
            if (begin >= Number.MAX_SAFE_INTEGER || end >= Number.MAX_SAFE_INTEGER) return;
        }

        if (inputValue === newVal) return;
        setInputValue(newVal);
    }

    const getOptionLabel = (opt: Option): string => {
        return opt.optionLabel || "";
    }

    const EmptySearchText = (<div style={{textAlign: "left"}}>
        {inputValue &&
            <Typography sx={{marginBottom: 1}}>No matches</Typography>
        }
        <Typography>
            Example region search:
        </Typography>
        <Typography variant="subtitle1" sx={{marginLeft: 4}}>chr1:55040960-55040969</Typography>

        <Typography>
            Example variant search:
        </Typography>
        <Typography variant="subtitle1" sx={{marginLeft: 4}}>chr1:55040966:SG</Typography>
    </div>)

    // @ts-ignore
    return (
        <>
            <Autocomplete
                sx={{textAlign: "left"}}
                disablePortal
                autoHighlight
                loading={isLoading}
                noOptionsText={EmptySearchText}
                filterOptions={(options, state) => options}
                isOptionEqualToValue={(option, value) => {
                    return option.type === value.type && option.optionLabel === value.optionLabel
                }}
                options={matchedValues}
                groupBy={(d: Option) => d.type}
                getOptionLabel={getOptionLabel}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                // key={currentSearchRender}
                // @ts-ignore
                onChange={navigateStuff}
                PopperComponent={StyledPopper}
                renderInput={renderInput}
            />
        </>
    );
};
export default DecafAutocomplete;
