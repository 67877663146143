import axios from "axios";
import {useParams} from "react-router-dom";
import React from "react";


export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export async function fetchGraphQL(query, variables) {
    // let res = await axios.post(
    let res = await axios.post(
        "/api",
        {
            "query": query,
            "variables": variables
        }
    );

    return res.data.data;
}

export function withPassRouteMatches(Component) {
    const WrappedComponent = (props) => {
        const params = useParams();

        return (
            <Component
                {...props}
                {...params}
            />
        );
    }
    return WrappedComponent;
}


export function capitalise(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatScientific(num) {
    let s;
    if (num === null || num === undefined) {
        s = ''
    } else {
        // num.toPrecision returns a string
        const truncated = Number(num.toPrecision(3))
        if (truncated === 0 || truncated === 1) {
            s = num.toFixed(0)
        } else {
            s = truncated.toExponential(2)
        }
    }
    return s;
}


export function formatVariantDisplayValue(varId, ref, alt) {
    const [chrom, pos] = varId.split(":");

    let displayChromPos;
    if (chrom && pos) {
        displayChromPos = `${chrom}-${pos}`;
    } else {
        displayChromPos = varId;
    }

    return `${displayChromPos}-${ref}-${alt}`
}


export default {
    fetchGraphQL: fetchGraphQL,
    withPassRouteMatches: withPassRouteMatches,
    capitalise: capitalise,
    formatScientific: formatScientific,
    clamp: clamp,
    formatVariantDisplayValue: formatVariantDisplayValue
}