import * as React from 'react';
import {useNavigate} from "react-router-dom";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from "axios";
import Swal from 'sweetalert2'

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#ac0533',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#ac0533',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ac0533',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ac0533',
        },
    },
});

const Feedback = () => {
    let navigate = useNavigate();

    const [submitterEmail, setSubmitterEmail] = React.useState("");
    const [feedback, setFeedback] = React.useState("");

    const handleChange = (event) => {
        setFeedback(event.target.value);
    };

    const submitFeedback = async () => {
        let res = await axios.post(
            "/routes/sendFeedback",
            {
                "feedback": feedback,
                "submitterEmail": submitterEmail
            }
        );

        if (res.data === "success") {
            Swal.fire({
                icon: 'success',
                title: 'Feedback sent',
                text: 'Thank you for leaving feedback. You will now be redirected to the front page.',
              }).then(res => {
                navigate("/")
              })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
              })
        }
    }

    return (
        <>
            <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={8} sx={{
                    '& .MuiTextField-root': { m: 1, width: '100ch', maxWidth: "100%" },
                }}>
                    <div>
                        <p>To send feedback to the team behind this page, please fill out the form below.</p>
                        <CssTextField
                            label="Your e-mail address goes here"
                            value={submitterEmail}
                            autoComplete='off'
                            onChange={event => setSubmitterEmail(event.target.value)}
                        />
                    </div>
                    <div>
                        <CssTextField
                            label="Feedback goes here"
                            multiline
                            rows={4}
                            autoComplete='off'
                            value={feedback}
                            onChange={event => setFeedback(event.target.value)}
                        />
                    </div>
                    <Button variant="outlined" classes={{ disabled: "disabledButton" }} style={{ margin: 8, height: 60, width: 200, color: "#ac0533", borderColor: "#ac0533" }}
                        disabled={feedback.length === 0 || submitterEmail.length === 0 || !submitterEmail.includes("@") || !submitterEmail.includes(".")} onClick={submitFeedback}>Submit</Button>
                </Grid>
            </Grid>
        </>
    );
};
export default Feedback;