import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';


const Terms = () => {
    return (
        <Grid container spacing={2} style={{textAlign: "left"}}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
                <h2 style={{textAlign: "center"}}>Terms of Use</h2>
                <p>deCAF is provided free of charge to the research community for research purposes only. It is not a medical device and may not be used for any diagnostic or medical purposes.</p>
            </Grid>
        </Grid>
    );
};
export default Terms;