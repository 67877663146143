import Card from "@mui/material/Card";
import InfoCardContent from "./InfoCardContent";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Button, Checkbox, FormControlLabel, FormGroup, Radio} from "@mui/material";
import {capitalise} from "../../utils/utils";
import {AgGridReact} from "ag-grid-react";
import React, {useMemo, useRef, useState} from "react";
import Grid from '@mui/material/Grid';

import './tables.css'


function VariantAggregateTable(props) {
    const {customColDefs, rowData, pageType, pageTypeValue} = props;

    const gridRef = useRef();

    const vepFilterOptions = [
        "lowest", "low", "moderate", "high"
    ];
    const vepFilterColors = ["green", "#7fd97f", "#c2c241", "darkred", "cornflowerblue"];
    const [selectedVepFilterOptions, setSelectedVepFilterOptions] = useState(vepFilterOptions);


    const defaultColDefs = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            sortable: true,
            // filter: true,
            filter: 'agTextColumnFilter',
            // enable floating filters by default
            floatingFilter: true,
            // wrapText: true,     // <-- HERE
            // autoHeight: true,   // <-- & HERE
        }
    }, []);


    const applyFilters = (newFilters) => {
        // filter stuff
        const impactFilter = gridRef.current.api.getFilterInstance('maxImpact');

        const allSelectedVep = vepFilterOptions.length === newFilters.length;
        // console.log("allSelected:", allSelectedVep);

        if (allSelectedVep) {
            impactFilter.setModel(null);
        } else {
            impactFilter.setModel({values: newFilters.map(opt => opt.toUpperCase())})
        }
        gridRef.current.api.onFilterChanged();
    }


    const handleVEPFilter = (event, sourceType) => {
        // 'LOW', 'MODERATE', 'HIGH', 'LOWEST', 'VEP_failed'
        const checked = event.target.checked;
        const value = event.target.value;
        // console.log("event", event);
        // console.log("checked:", checked, ", value:", value);


        let newFilters;
        if (checked) {
            newFilters = selectedVepFilterOptions.concat(value);
        } else {
            newFilters = selectedVepFilterOptions.filter((opt) => opt !== value);
        }


        // console.log("newFilters:", newFilters);
        setSelectedVepFilterOptions(newFilters);
        applyFilters(newFilters)
        // setRadioSelected("");
    }

    const handleVEPFilterRadio = (event, sourceType) => {
        // console.log("event RADIO:", event);
        const checked = event.target.checked;
        const value = event.target.value;
        // console.log("event", event);
        // console.log("checked:", checked, ", value:", value);

        const newFilters = [value];
        setSelectedVepFilterOptions(newFilters);
        // setRadioSelected(value);
        applyFilters(newFilters);
    }


    // const [gridApi, setGridApi] = useState(null);
    //
    // const sizeToFit = () => {
    //     if(gridApi){
    //         gridApi.sizeColumnsToFit();
    //     }
    // };
    //
    // const handleFirstDataRender = (params) => {
    //     sizeToFit();
    // }
    //
    // // TODO this sizeToFit logic causes a memory leak, fix.
    // useEffect(() => {
    //     setTimeout(() => {
    //         window.addEventListener("resize",  sizeToFit);
    //     });
    //     return () => window.removeEventListener("resize", sizeToFit);
    // }, [gridApi]);
    //
    // const onGridReady = (params) => {
    //     setGridApi(params.api);
    //     // setGridColumnApi(params.columnApi);
    // };

    return (
        <div className="ag-theme-alpine"
             style={{
                 height: "75vh", width: "90%", margin: "0 auto", textAlign: "left"
             }}>
            <Card
            >
                <InfoCardContent pageType={pageType} pageTypeValue={pageTypeValue}
                />
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>VEP Impact:</Typography>
                    <FormGroup row
                        // onChange={handleVEPFilter}
                               sx={{width: "100%"}}
                    >
                        {/*<FormLabel style={{marginRight: "8px"}} component="legend">VEP impact: </FormLabel>*/}
                        {vepFilterOptions.map((opt, i) => (
                            <FormControlLabel key={opt}
                                // sx={{border: `1px solid #a8a8a8`, borderRadius: 1}}
                                // sx={{borderRight: `1px solid #a8a8a8`}}
                                              sx={{
                                                  border: `1px solid ${vepFilterColors[i]}`,
                                                  borderRadius: 1,
                                                  borderOpacity: 0.6
                                              }}
                                              label={<span>{capitalise(opt)}
                                                  <Radio
                                                      style={{
                                                          color: vepFilterColors[i],
                                                          // marginLeft: "-6px"
                                                      }}
                                                      // checked={radioSelected === opt}
                                                      checked={selectedVepFilterOptions.length === 1 && selectedVepFilterOptions[0] === opt}
                                                      onChange={handleVEPFilterRadio}
                                                      // onClick={(e) => e.target.checked = !e.target.checked}
                                                      value={opt}
                                                  />
                                                        </span>
                                              }
                                              control={<Checkbox
                                                  // style={{color: "#ac0533"}}
                                                  style={{color: vepFilterColors[i]}}
                                                  // xs={{color: vepFilterColors[i]}}
                                                  checked={selectedVepFilterOptions.indexOf(opt) > -1}
                                                  value={opt}
                                                  onChange={handleVEPFilter}
                                              />}
                            />
                        ))}
                        <Button
                            onClick={() => {
                                const newFilters = vepFilterOptions.slice();
                                setSelectedVepFilterOptions(newFilters);
                                applyFilters(newFilters);
                            }}
                            variant="outlined"
                            // size="small"
                            // color="text.primary"
                            sx={{
                                p: 0,
                                // color: "text.primary",
                                // borderColor: "rgba(103, 103, 103, 1)"
                            }}
                            // style={{
                            //     color: "#46AD8D",
                            //     // backgroundColor: "#fff"
                            //     contrastText: "#fff"
                            // }}
                        >
                            All
                        </Button>
                    </FormGroup>
                </CardContent>
            </Card>
            <br/>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={customColDefs}
                defaultColDef={defaultColDefs}
                // onGridReady={onGridReady}
                // onFirstDataRendered={handleFirstDataRender}
                // getRowId={(d) => d.data.uniqueId}
                pagination={true}
                rowBuffer={35}
                // animateRows={true}
                sideBar={{
                    toolPanels: [
                        {
                            id: "columns",
                            labelDefault: "Columns",
                            labelKey: "columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel",
                            toolPanelParams: {
                                suppressRowGroups: true,
                                suppressValues: true,
                                suppressPivots: true,
                                suppressPivotMode: true,
                            }
                        }
                    ]
                }}

                // debounceVerticalScrollbar={true}
            >
            </AgGridReact>
        </div>
    );
}

export default VariantAggregateTable;



