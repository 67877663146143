import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PolicyIcon from '@mui/icons-material/Policy';
import ChatIcon from '@mui/icons-material/Chat';

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import { useNavigate } from "react-router-dom";

import logo2 from "./decode2.png";
import logo from "./decode3.png";
import SearchElement from "../Search/Search";


const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const ResponsiveAppBar = () => {
    let navigate = useNavigate();
    //react useState hook to save the current open/close state of the drawer, normally variables dissapear afte the function was executed
    const [open, setOpen] = React.useState(false);

    // @ts-ignore
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        //changes the function state according to the value of open
        setOpen(open);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "#ac0533" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={logo} style={{ height: 60, marginTop: 5, cursor: "pointer" }} onClick={() => navigate(`/`, { replace: true })} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={toggleDrawer(true)}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={open}
                            onClose={toggleDrawer(false)}
                        >
                            {/* The inside of the drawer */}
                            <Box
                                sx={{
                                    paddingLeft: 2,
                                    paddingRight: 4,
                                    height: 1,
                                    // backgroundColor: "#dbc8ff"
                                }}
                            >
                                {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                                <IconButton sx={{ mb: 0 }} onClick={toggleDrawer(false)}>
                                    <img src={logo2} style={{ height: 50, marginRight: 20, cursor: "pointer" }} />
                                    <CloseIcon fontSize="medium" />
                                </IconButton>

                                <Divider sx={{ mb: 2 }} />

                                <Box sx={{ mb: 2 }}>
                                    <ListItemButton onClick={() => { setOpen(false); navigate(`/`, { replace: true }) }}>
                                        <ListItemIcon>
                                            <HomeIcon sx={{ color: "rgb(172, 5, 51)" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Home" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => { setOpen(false); navigate(`/about`, { replace: true })}}>
                                        <ListItemIcon>
                                            <InfoIcon sx={{ color: "rgb(172, 5, 51)" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="About" />
                                    </ListItemButton>

                                    <ListItemButton onClick={() => { setOpen(false); navigate(`/terms`, { replace: true })}}>
                                        <ListItemIcon>
                                            <PolicyIcon sx={{ color: "rgb(172, 5, 51)" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Terms" />
                                    </ListItemButton>


                                    <ListItemButton onClick={() => { setOpen(false); navigate(`/feedback`, { replace: true })}}>
                                        <ListItemIcon>
                                            <ChatIcon sx={{ color: "rgb(172, 5, 51)" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Feedback" />
                                    </ListItemButton>
                                </Box>
                            </Box>
                        </Drawer>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', paddingTop: 5 } }}>
                        <Button
                            key={0}
                            onClick={() => navigate(`/`, { replace: true })}
                            sx={{ my: 2, marginLeft: 2, color: 'white', fontWeight: window.location.pathname === ("/") ? "700" : "100", display: 'block' }}
                        >
                            Home
                        </Button>
                        <Button
                            key={1}
                            onClick={() => navigate(`/about`, { replace: true })}
                            sx={{ my: 2, marginLeft: 2, color: 'white', fontWeight: window.location.pathname.includes("about") ? "700" : "100", display: 'block' }}
                        >
                            About
                        </Button>
                        <Button
                            key={2}
                            onClick={() => navigate(`/terms`, { replace: true })}
                            sx={{ my: 2, marginLeft: 2, color: 'white', fontWeight: window.location.pathname.includes("terms") ? "700" : "100", display: 'block' }}
                        >
                            Terms
                        </Button>
                        <Button
                            key={3}
                            onClick={() => navigate(`/feedback`, { replace: true })}
                            sx={{ my: 2, marginLeft: 2, color: 'white', fontWeight: window.location.pathname.includes("feedback") ? "700" : "100", display: 'block' }}
                        >
                            Send Feedback
                        </Button>
                    </Box>
                    {window.location.pathname !== "/" &&
                        <SearchElement />}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;